import { makeStyles } from "@material-ui/core/styles";
import {
  calculateFontSize,
  setFontColorOnBg,
} from "../../../CustomHooks/useThemeHook";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  headerDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "25px",
  },
  headerLogoDiv: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  headerLogo: {
    height: "45px",
    width: "auto",
  },
  title: {
    // ...theme.typography.rockwell,
    fontSize: calculateFontSize(20, theme.palette.common.fontVariation),
    fontWeight: 700,
    margin: theme.spacing(0.4),
    color: theme.palette.common.headerFontColor,
  },
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  item: {
    userSelect: "none",
    flexGrow: 1,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
    border: theme?.palette?.button?.buttonBorder
      ? "1px solid black"
      : "1px solid black",
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    cursor: "pointer",
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
    backgroundColor:
      theme?.palette?.button?.buttonColor || theme.palette.common.white,
    "&:hover": {
      opacity: 0.8,
    },
    "&.selected": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
  itemText: {
    fontSize: calculateFontSize(15, theme.palette.common.fontVariation),
    fontWeight: 900,
    // color: theme.palette.common.normalTitle,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),

    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemDiv: {
    display: "flex",
    width: "20%",
    alignItems: "center",
    justifyContent: "center",
  },
  itemIcon: {
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.common.white
    ),
    "&.selected": {
      color: theme.palette.common.white,
    },
  },
  itemDisabled: {
    opacity: 0.5,
    pointerEvents: "none",
  },
  modalTitleSection: {
    marginBottom: theme.spacing(1.6),
    display: "flex",
  },
  modalTitle: {
    fontSize: calculateFontSize(16, theme.palette.common.fontVariation),
    fontWeight: 700,
    color: theme?.palette?.common?.themeFontColor,
  },
  dateTimeSelection: {
    width: "50%",
    marginBottom: "10px",
    // color: theme.palette.common.normalTitle,
    // color: theme?.palette?.card?.cardFontColor,

    padding: "0 1% !important",
    "& :first-of-type": {
      marginLeft: "0",
      color: theme?.palette?.card?.cardFontColor,
      // color: theme.palette.common.normalTitle,
    },
    "& fieldset": {
      border: "none !important",
    },
  },
  btnDiv: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  continueBtn: {
    height: "33px",
    fontSize: calculateFontSize(14, theme.palette.common.fontVariation),
    backgroundColor: theme?.palette?.button?.buttonColor || "transparent",
    // border: `1px solid ${theme.palette.primary.main}`,
    color: setFontColorOnBg(
      theme?.palette?.button?.buttonColor || theme.palette.button.main
    ),
    // color: theme.palette.common.black,
    margin: theme.spacing(0.4, 0.4),
    borderRadius: theme?.palette?.button?.borderRadius || "12px",
    border: theme?.palette?.button?.buttonBorder ? "1px solid black" : null,
    boxShadow: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textTransform: "none",
    "&:hover": {
      color: "#fff",
      pointer: "cursor",
      borderRadius: "10px",
    },
  },
}));

export default useStyles;
