import React, { useEffect, useState } from "react";
import clsx from "clsx";
// Material
import Typography from "@material-ui/core/Typography";
// Custom Hooks
import useDynamicHotelData from "../../../CustomHooks/useDynamicHotelData";
// Styles
import useStyles from "./SelectOrderType.style.js";
import { Button, Divider } from "@material-ui/core";
import DeliveryTime from "../DeliveryTime/DeliveryTime.js";
import DateSlotPicker from "../../Checkout/DateSlotPicker/index.js";
import { useSelector } from "react-redux";
import { ORDER_TYPES } from "../../../utils/Constants.js";
import { generateDateSlots } from "../../../utils/utils.js";
import TimePicker from "../../Checkout/TimePicker/index.js";
import moment from "moment";

const SelectOrderType = ({
  value = null,
  onSelect = () => {},
  formValues,
  handleClose,
  onDateAndTimeSelect,
}) => {
  const classes = useStyles();
  const { hotel } = useSelector((state) => state.hotel);
  const [selectedOderType, setOrderType] = useState(value);
  const [dateSlots, setDateSlots] = useState([]);
  const [dateSlotsError, setDateSlotsError] = useState(false);
  const [date, setDate] = useState(null);
  const [typeOfOrder, setTypeOfOrder] = useState("SCHEDULED");
  const [time, setTime] = useState(null);
  const { theme } = useSelector((state) => state?.theme);

  const {
    getOrderTypes,
    getDeliveryIntervalSlots,
    getTodayDeliveryIntervalSlots,
    isAsapAvailable,
    isMultipleOrderType,
  } = useDynamicHotelData();

  const generateDatePickerSlots = () => {
    let orderTypeValue = value;
    if (value === ORDER_TYPES.TABLE_BOOKING_AND_ORDERING) {
      orderTypeValue = ORDER_TYPES.OPENING;
    }
    const dates = generateDateSlots(hotel, orderTypeValue) || [];
    setDateSlots(dates);
    if (!dates || !dates?.length) {
      setDateSlotsError(true);
    }
  };

  useEffect(() => {
    const timeString = `${date}${time ? ` ${time}` : ""}`;
    const dTime = moment(moment(timeString)).toISOString();
    onDateAndTimeSelect(typeOfOrder || "SCHEDULED", dTime);
  }, [date, time, typeOfOrder]);

  useEffect(() => {
    if (hotel || formValues?.orderType || value) {
      generateDatePickerSlots();
    }
  }, [hotel, formValues?.orderType, value]);

  const onOrderTypeSelect = (fieldName, type) => {
    setOrderType(type);
    onSelect(fieldName, type);
  };

  const onSelectDataAndTime = ({ value, typeOfOrder }, type) => {
    if (!value) {
      return;
    }

    if (type === "date") {
      setDate(value);
    }

    if (type === "time") {
      setTime(value);
    }

    if (typeOfOrder) {
      setTypeOfOrder(typeOfOrder);
    }
  };

  return (
    <>
      <div className={classes.headerDiv}>
        {theme?.header_logo ? (
          // Otherwise, render the image with regular URL
          <div className={classes.headerLogoDiv}>
            <img
              src={`data:image/png;base64, ${theme?.header_logo}`}
              alt="Header Logo"
              className={classes.headerLogo}
            />
          </div>
        ) : (
          <Typography
            className={clsx(classes.title)}
            // style={{ color: darkTheme ? "white" : "black" }}
            variant="h1"
            // noWrap
            display="block"
          >
            {/* {APP_NAME} */}
            {hotel ? hotel.hotelName : ""}
          </Typography>
        )}
      </div>
      {isMultipleOrderType && (
        <div className={classes.modalTitleSection}>
          <Typography
            className={classes.modalTitle}
            variant="h1"
            align="center"
            paragraph
          >
            {"Select Order Type"}
          </Typography>
          <Divider />
        </div>
      )}
      {isMultipleOrderType && (
        <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
          {getOrderTypes.map((o, i) => (
            <div
              key={i}
              className={clsx(
                classes.item,
                o.type === selectedOderType && "selected"
                // o.active === false && classes.itemDisabled
              )}
              onClick={() => onOrderTypeSelect("orderType", o.type)}
            >
              <div className={classes.itemDiv}>
                <o.Icon
                  className={clsx(
                    classes.itemIcon,
                    o.type === selectedOderType && "selected"
                  )}
                />
              </div>
              <Typography
                // color="primary"
                className={clsx(
                  classes.itemText,
                  o.type === selectedOderType && "selected"
                )}
                variant="caption"
              >
                {o.title}
              </Typography>
            </div>
          ))}
        </div>
      )}
      <div className={classes.modalTitleSection}>
        <Typography
          className={classes.modalTitle}
          variant="h1"
          align="center"
          paragraph
        >
          {"Select Order Time"}
        </Typography>
        <Divider />
      </div>

      <div id="checkout-orderTypeForm" className={clsx(classes.container)}>
        <DateSlotPicker
          slots={dateSlots}
          className={`w-100 ${classes.dateTimeSelection}`}
          onChange={onSelectDataAndTime}
        />
        <TimePicker
          slots={
            moment(formValues.deliveryTime).isSame(moment(), "date") &&
            getTodayDeliveryIntervalSlots.length > 0
              ? getTodayDeliveryIntervalSlots
              : getDeliveryIntervalSlots
          }
          date={date}
          className={`w-100 ${classes.dateTimeSelection}`}
          onChange={onSelectDataAndTime}
        />
      </div>
      <div className={classes.btnDiv}>
        <Button
          disabled={!formValues?.orderType}
          className={classes.continueBtn}
          onClick={handleClose}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default SelectOrderType;
