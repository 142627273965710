import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  // Define your styles here
  ul: {
    background: theme.palette.card.cardColor,
    color: theme?.palette?.card?.cardFontColor,
    "& ul": {
      color: theme.palette.card.cardFontColor,
      background: theme.palette.card.cardColor,
    },
  },
  selectMenu: {
    background: theme.palette.card.cardColor,
    "& li": {
      color: theme.palette.card.cardFontColor,
    },
  },
}));

function Index({ slots, className, value, onChange, date }) {
  const [selectedDate, setSelectedDate] = useState({});
  const classes = useStyles();

  useEffect(() => {
    if (selectedDate?.value && !slots.includes(selectedDate)) {
      /* ########################
        handle rerender of this component on deliverydate updation from delivery time slot generating hooks
        this results in creation of new slots which fails to match the object in our selectedDate state.
        This is a hack only
        #########################
      */
      const match = slots.find((s) => s.value === selectedDate.value);
      if (match) {
        return setSelectedDate(match);
      }
    }

    const SelectedTimeFromSlots = slots.find(
      (s) => s.value === selectedDate.value
    );
    if (!SelectedTimeFromSlots) {
      const data = slots[0];
      if (data) {
        setSelectedDate(data);
        onChange(data, "time");
      }

      return;
    }

    if (selectedDate?.value) {
      onChange(selectedDate, "time");
    } else {
      const data = slots[0];
      if (data) {
        setSelectedDate(data);
        onChange(data, "time");
      }
    }
  }, [selectedDate, slots]);
  return (
    <FormControl variant="outlined" className={className}>
      {/* <InputLabel id="delivery-time">Time</InputLabel> */}
      <Select
        labelId="delivery-time"
        id="delivery-time-select"
        value={selectedDate}
        onChange={(e) => setSelectedDate(e.target.value)}
        label="Time"
        MenuProps={{
          classes: { paper: classes.selectMenu },
        }}
        className={`font-weight-bold ${classes.ul}`}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {slots.map((elem, key) => (
          <MenuItem key={`option-${key}`} value={elem}>
            {elem.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

Index.defaultProps = {
  buffer: null,
};

Index.propTypes = {
  buffer: PropTypes.number,
  className: PropTypes.string.isRequired,
};

export default Index;
