import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  layoutPadding: {
    padding: theme.spacing(2.4, 1.6, 0, 1.6),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.4, 2, 0, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2.4, 12, 0, 12),
    },
  },
  bodyContainerMainHeader: {
    height: "calc(100% - (15px + 49px))",
    // padding: theme.spacing(2.4, 1.6) //hiding for now to add sitefooter under scroll panel
  },
  contentContainer: {
    padding: theme.spacing(0.6, 1.6),
  },
}));

export default useStyles;
